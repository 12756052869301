import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import VideoPlayer from './VideoPlayer';

const VideoPlayerQuery = props => {
  const title = idx(props, _ => _.title);
  const copy = idx(props, _ => _.copy.copy);
  const videoUrl = idx(props, _ => _.videoUrl);
  const reverse = idx(props, _ => _.reverse);
  return <VideoPlayer title={title} copy={copy} videoUrl={videoUrl} reverse={reverse} />;
};

export default VideoPlayerQuery;

export const query = graphql`
  fragment VideoPlayer on ContentfulVideoPlayer {
    title
    copy {
      copy
    }
    videoUrl
  }
`;
