import React from 'react';
import { css } from '@utils';

class Video extends React.Component {
  render() {
    const { src, decorations, forwardedRef, alt } = this.props;

    return (
      <video
        className={css(decorations)}
        playsInline
        ref={forwardedRef}
        muted
        autoPlay
        loop
        alt={alt}
      >
        <source src={src} type="video/mp4" />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>
    );
  }
}

const VideoWithRef = React.forwardRef((props, ref) => {
  return <Video {...props} forwardedRef={ref} />;
});

export default VideoWithRef;
