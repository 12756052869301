import React from 'react';
import { css } from '@utils';
import styles from './VideoPlayer.module.scss';
import ReactPlayer from 'react-player';

class VideoPlayer extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
    }
  }

  render() {
    const { isPlaying } = this.state;
    const { videoUrl, title, copy, reverse } = this.props;
    return (
        <div className={css(styles.wrapper, reverse && styles.reverse)}>
            <div className={css(styles.videoWrapper)}>
              <h2 className={css(styles.videoTitle, isPlaying && styles.videoTitleHidden)}>{title}</h2>
              <div className={css(styles.videoPlayer)}>
                <div className={css(styles.videoPlayerIn)}>
                  <ReactPlayer 
                    className={css(styles.player)}
                    url={videoUrl} 
                    controls={true}
                    width='100%'
                    height='100%'
                    onPlay={() => {this.setState({isPlaying: true})}}
                    onPause={() => {this.setState({isPlaying: false})}}
                    onEnded={() => {this.setState({isPlaying: false})}}
                  />
                </div>
              </div>
            </div>
            <div className={css(styles.copyWrapper)}>
              <p>{copy}</p>
            </div>
        </div>
    );
  }
}

export default VideoPlayer;
