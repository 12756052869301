import React from 'react';
import { css } from '@utils';
import styles from './Download.module.scss';

class Download extends React.PureComponent {
  render() {
    const { title, copy, sections } = this.props;
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.copyWrapper)}>
          <h2 className={css(styles.title)}>{title}</h2>
          <p>{copy}</p>
        </div>

        {!!sections &&
          sections.map((section, i) => (
            <div className={css(styles.section)} key={i}>
              <p className={css(styles.eyebrow)}>{section.title}</p>

              <ul className={css(styles.list)}>
                {!!section.links &&
                  section.links.map((link, j) => {
                    return (
                      <li key={j} className={css(styles.item)}>
                        <a
                          href={link.url}
                          rel="noopener noreferrer"
                          target="_blank"
                          className={css(styles.link)}
                        >
                          <div className={css(styles.content)}>{link.copy}</div>
                          <div className={css(styles.downloadIcon)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="22"
                              viewBox="0 0 21 22"
                            >
                              <g transform="translate(-1224.5 -7356.5)">
                                <path
                                  d="M2699.043,6036.32l6.619,6.619-6.619,6.619"
                                  transform="translate(7278.059 4665.468) rotate(90)"
                                  fill="none"
                                  strokeWidth="2"
                                />
                                <line
                                  x2="21"
                                  transform="translate(1224.5 7377.5)"
                                  fill="none"
                                  strokeWidth="2"
                                />
                                <line
                                  y1="14"
                                  transform="translate(1235 7356.5)"
                                  fill="none"
                                  strokeWidth="2"
                                />
                              </g>
                            </svg>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ))}
      </div>
    );
  }
}

export default Download;
