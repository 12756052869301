import React from 'react';
import { css } from '@utils';
import {
  TwitterIcon,
  FacebookIcon,
  LinkedInIcon,
  InstagramIcon,
  YoutubeIcon
} from '../Social/SocialIcons';
import idx from 'idx';
import styles from './SocialsLinks.module.scss';

class SocialsLinks extends React.PureComponent {
  render() {
    const { title, copy, link, items } = this.props;
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.copyWrapper)}>
          <h2 className={css(styles.title)}>{title}</h2>
          <p>{copy}</p>
        </div>

        <div className={css(styles.list)}>
          {items &&
            items.map((item, i) => {
              const icons = {
                Instagram: InstagramIcon,
                Twitter: TwitterIcon,
                Facebook: FacebookIcon,
                LinkedIn: LinkedInIcon,
                Youtube: YoutubeIcon
              };

              const Icon = icons[item.icon] || YoutubeIcon;

              return (
                <a
                  href={item.url}
                  aria-label={item.icon || 'Social Media'}
                  target="_blank"
                  key={i}
                  className={css(styles.item)}
                >
                  <Icon className={css(styles.icon)} />
                </a>
              );
            })}
        </div>

        {link && (
          <a
            className={css(styles.link)}
            href={idx(link, _ => _.media.file.url) || link.url}
            target="_blank"
          >
            {link.copy}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.148"
              height="8.047"
              viewBox="0 0 9.148 8.047"
            >
              <g transform="translate(4014.558 -7.541) rotate(90)">
                <g transform="translate(-3.685 4005.789)">
                  <path
                    d="M815.7,492.551l4.531,3.884-4.531,4.531"
                    transform="translate(-801.694 -492.551)"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <line
                    x1="7.311"
                    transform="translate(11.227 3.941)"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                </g>
              </g>
            </svg>
          </a>
        )}
      </div>
    );
  }
}

export default SocialsLinks;
