import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Vision from './Vision';

const VisionQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const headline = idx(props, _ => _.headline);
  const copy = idx(props, _ => _.copy.copy);
  const video = idx(props, _ => _.video);

  const iconBulletPoints = idx(props, _ =>
    _.iconBulletPoints.map(bulletPoint => {
      return {
        iconImage: idx(bulletPoint, _ => _.iconImage.fluid),
        iconImageAlt: idx(bulletPoint, _ => _.iconImage.description),
        title: idx(bulletPoint, _ => _.title),
        copy: idx(bulletPoint, _ => _.copy.copy)
      };
    })
  );

  return (
    <Vision
      eyebrow={eyebrow}
      headline={headline}
      copy={copy}
      video={video}
      iconBulletPoints={iconBulletPoints}
    />
  );
};

export default VisionQuery;

export const query = graphql`
  fragment Vision on ContentfulVision {
    eyebrow
    headline
    copy {
      copy
    }
    video {
      description
      file {
        url
        fileName
        contentType
      }
    }
    iconBulletPoints {
      title
      iconImage {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      copy {
        copy
      }
    }
  }
`;
