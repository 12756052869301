import React from 'react';
import CharacterReveal from '@shared/Reveals/CharacterReveal';
import InView from '@shared/InView/InView';
import ExploreMore from '@shared/ExploreMore/ExploreMore';
import LogoAnimated from '@shared/LogoAnimated/LogoAnimated';
import { css, constrain } from '@utils';

import styles from './Hero.module.scss';

const SHEEN_STOPS = [15, 50, 85];

class Hero extends React.PureComponent {
  constructor(props) {
    super(props);
    this.sheenOffset = -50;
    this.state = {
      heroBoundingRect: null,
      pageMounted: false
    };
    this.inViewTimeoutId = null;
    this.isAnimating = true;
    this.currentTime = 0;
  }

  componentDidMount() {
    this.onResize();
    this.addEventListeners();
    this.setState({ pageMounted: true });
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  onMouseMove = e => {
    if (!this.isAnimating) {
      const { heroBoundingRect } = this.state;
      const xOffset = (e.clientX / heroBoundingRect.width - 0.5) * 50;
      const yOffset = (e.clientY / heroBoundingRect.height - 0.5) * 50;
      const sheenOffset = xOffset + yOffset;

      this.animateSheen(sheenOffset, 1);
    }
  };

  onDeviceOrientation = e => {
    if (!this.isAnimating) {
      if (e.gamma && e.beta) {
        const xOffset = constrain(e.gamma * 2, -25, 25);
        const yOffset = constrain(e.beta * 2, -25, 25);
        const sheenOffset = xOffset + yOffset;
        this.animateSheen(sheenOffset, 1);
      }
    }
  };

  addEventListeners = () => {
    window.addEventListener('resize', this.onResize);
    this.hero.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('deviceorientation', this.onDeviceOrientation);
  };

  removeEventListeners = () => {
    window.removeEventListener('resize', this.onResize);
    this.hero.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('deviceorientation', this.onResize);
  };

  onResize = () => {
    this.setState({ heroBoundingRect: this.hero.getBoundingClientRect() });
  };

  animateSheen = (targetOffset, interval) => {
    const { sheenOffset } = this;
    this.targetOffset = targetOffset;
    this.offsetDelta = targetOffset - sheenOffset;

    return new Promise((resolve, reject) => {
      if (this.sheenAnimationInterval) {
        clearInterval(this.sheenAnimationInterval);
      }

      this.sheenAnimationInterval = setInterval(() => {
        const currentSheen = this.sheenOffset;
        const localSheenOffset = currentSheen;

        if (Math.abs(this.targetOffset - localSheenOffset) > 1) {
          this.sheenOffset = localSheenOffset + this.offsetDelta / 50;
        } else {
          clearInterval(this.sheenAnimationInterval);
          this.sheenOffset = this.targetOffset;
          resolve();
        }

        const sheenStops = this.calculateSheenStop();
        const sheenPosition = sheenStops[1];
        this.ref.setAttribute('cx', sheenPosition);
        this.ref.setAttribute('cy', sheenPosition);
        this.ref.setAttribute('fx', sheenPosition);
        this.ref.setAttribute('fy', sheenPosition);
      }, interval);
    });
  };

  onInView = inView => {
    const { setPageSection } = this.props;
    if (inView && setPageSection) {
      this.isAnimating = true;
      setPageSection('Hero');
      this.inViewTimeoutId = setTimeout(() => {
        this.animateSheen(50, 20)
          .then(() => {
            this.animateSheen(0, 20);
          })
          .then(() => {
            this.isAnimating = false;
          });
      }, 1000);
    } else {
      clearInterval(this.sheenAnimationInterval);
      clearTimeout(this.inViewTimeoutId);
    }
  };

  logoFinishedAnimating = () => {
    this.setState({ logoFinishedAnimating: true });
  };

  calculateSheenStop = () => {
    const baseStops = SHEEN_STOPS;
    const sheenStops = [];
    sheenStops[0] = `${constrain(baseStops[0] + this.sheenOffset, 0, baseStops[2])}%`;
    sheenStops[1] = `${constrain(baseStops[1] + this.sheenOffset, baseStops[0], baseStops[2])}%`;
    sheenStops[2] = `${constrain(baseStops[2] + this.sheenOffset, baseStops[0], 100)}%`;

    return sheenStops;
  };

  render() {
    const { canPlay, heroHeadline, noLogoAnim, noHeader } = this.props;
    const { pageMounted, logoFinishedAnimating } = this.state;
    const sheenStops = this.calculateSheenStop();
    const sheenPosition = sheenStops[1];
    const heroHeadlineSeparated = [...heroHeadline.split(' ')];

    return (
      <InView onChange={this.onInView} rootMargin="-59.9% 0% -39.9% 0%" triggerOnce>
        <div
          id="hero"
          className={css(styles.heroWrapper, noHeader && styles.heroWrapperNoHeader)}
          ref={r => {
            this.hero = r;
          }}
        >
          <div className={css(styles.sheenWrapper, pageMounted && styles.mounted)}>
            {!noLogoAnim && <LogoAnimated isComplete={this.logoFinishedAnimating} />}
            <svg
              viewBox="0 0 153.47 178.24"
              preserveAspectRatio="xMinYMin meet" // This keeps the logo aligned left in IE
              className={css(styles.sheen, pageMounted && logoFinishedAnimating && styles.visible)}
            >
              <defs>
                <radialGradient
                  ref={r => {
                    this.ref = r;
                  }}
                  id="sheenGradient"
                  cx={sheenPosition}
                  cy={sheenPosition}
                  r="50%"
                  fx={sheenPosition}
                  fy={sheenPosition}
                >
                  <stop offset="0%" stopColor="rgb(255, 255, 255)" stopOpacity="0.7" />
                  <stop offset="100%" stopColor="rgb(255, 255, 255)" stopOpacity="0.0" />
                </radialGradient>
              </defs>
              <path d="M97.26,80.13c14.43-1.38,23-10.25,23-23.72S111.16,33.55,97,32.66H0V47.56H96.3c6,.44,9.09,3.41,9.09,8.85,0,3.88-1.1,8-9.22,8.84H0V80.16H96.91ZM97.23,0H0V14.91H96.88c29.2.92,41.66,13.35,41.66,41.54,0,26.69-12.9,39.51-41.8,41.54H0v14.91H97.25l.25,0c37.13-2.52,56-21.51,56-56.44C153.46,20.14,134.54,1.14,97.23,0ZM72,130.66H0v14.9H72.7C72.4,140.56,72.2,135.56,72,130.66ZM0,163.26v14.9H74.9c-.4-5-.7-9.9-1.1-14.9Z" />
            </svg>
          </div>
          <h1 className={css(styles.heroHeadline)}>
            <CharacterReveal
              animateOpacity
              canPlay={canPlay}
              characterOffsetDelay={25} // ms
              characterWordSpacing="10px"
              copy={heroHeadlineSeparated}
              from="top"
              duration={1275} // ms
              delay={1200}
              ease="cubic-bezier(0,0.4,0.4,1)"
              offset="45px"
              multilineMasking={false}
              multilineOffsetDelay={200}
            />
          </h1>
        </div>
        <ExploreMore />
      </InView>
    );
  }
}

export default Hero;
