import React from 'react';
import CharacterReveal from '@shared/Reveals/CharacterReveal';
import InView from '@shared/InView/InView';
import { css } from '@utils';
import styles from './QuestionsFeedback.module.scss';

const QuestionsFeedback = props => {
  const { title, copy } = props;

  return (
    <div className={css(styles.contact)}>
      <div className={css(styles.left)}>
        <InView triggerOnce>
          {inView => {
            return (
              <div className={css(styles.headline, inView && styles.inView)}>
                <CharacterReveal
                  animateOpacity
                  canPlay={inView}
                  characterOffsetDelay={15}
                  characterWordSpacing="10px"
                  copy={[title]}
                  from="bottom"
                  delay={500}
                  duration={700}
                  ease="cubic-bezier(0,0.4,0.4,1)"
                  offset="25px"
                  multilineMasking={false}
                  multilineOffsetDelay={100}
                />
              </div>
            );
          }}
        </InView>
      </div>
      <div className={css(styles.right)} dangerouslySetInnerHTML={{__html: copy}}>
      </div>
    </div>
  );
};

export default QuestionsFeedback;
