import React from 'react';
import { css, constrain, isIE } from '@utils';
import styles from './LogoAnimated.module.scss';

const LOGO_COLOR = 'rgb(255, 255, 255)';
const LOGO_STOP_OPACITY = '0.15';

class LogoAnimated extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      arcOuterLength: 0,
      arcInnerLength: 0,
      lineOffsets: {
        inner: 100,
        outer: 100,
        top: 100,
        bottom: 100
      }
    };
  }

  componentDidMount() {
    const { isComplete } = this.props;
    const arcOuterLength = this.arcOuter.getTotalLength();
    const arcInnerLength = this.arcInner.getTotalLength();
    if (isIE()) {
      this.setState({
        lineOffsets: {
          inner: 0,
          outer: 0,
          top: 0,
          bottom: 0
        }
      });
      isComplete();
    } else {
      this.setState({ arcOuterLength, arcInnerLength });
      this.startAnimation();
    }
  }

  componentWillUnmount() {
    clearInterval(this.outerLineInterval);
    clearInterval(this.outerLineInterval);
    clearInterval(this.topLineInterval);
    clearInterval(this.bottomLineInterval);
  }

  startAnimation = () => {
    setTimeout(() => {
      this.animateLine('outer', this.outerLineInterval, 1);
    }, 0);
    setTimeout(() => {
      this.animateLine('inner', this.outerLineInterval, 1);
    }, 550);
    setTimeout(() => {
      this.animateLine('top', this.topLineInterval, 5);
    }, 2090);
    setTimeout(() => {
      this.animateLine('bottom', this.bottomLineInterval, 5);
    }, 2210);
  };

  animateLine = (line, lineInterval, speed) => {
    const { isComplete } = this.props;
    clearInterval(lineInterval);
    lineInterval = setInterval(() => {
      const { lineOffsets } = this.state;
      if (lineOffsets[line] > 0) {
        const lineOffsetsUpdated = { ...lineOffsets };
        lineOffsetsUpdated[line] = constrain(lineOffsets[line] - speed, 0, 100);
        this.setState({ lineOffsets: lineOffsetsUpdated });
      } else {
        clearInterval(lineInterval);
        if (line === 'bottom') {
          isComplete();
        }
      }
    }, 16.67);
  };

  render() {
    const { arcOuterLength, arcInnerLength, lineOffsets } = this.state;

    return (
      <svg
        className={css(styles.logo)}
        viewBox="0 0 153.45 178.15"
        preserveAspectRatio="xMinYMin meet" // This keeps the logo aligned left in IE
      >
        <defs>
          <linearGradient id="animatedLogoGradientTop">
            <stop offset={`${0}%`} stopColor="rgb(255, 255, 255)" stopOpacity="0.0" />
            <stop offset={`${lineOffsets.top}%`} stopColor="rgb(255, 255, 255)" stopOpacity="0.0" />
            <stop
              offset={`${lineOffsets.top}%`}
              stopColor={LOGO_COLOR}
              stopOpacity={LOGO_STOP_OPACITY}
            />
            <stop offset={`${100}%`} stopColor={LOGO_COLOR} stopOpacity={LOGO_STOP_OPACITY} />
          </linearGradient>
          <linearGradient id="animatedLogoGradientBottom">
            <stop offset={`${0}%`} stopColor="rgb(255, 255, 255)" stopOpacity="0.0" />
            <stop
              offset={`${lineOffsets.bottom}%`}
              stopColor="rgb(255, 255, 255)"
              stopOpacity="0.0"
            />
            <stop
              offset={`${lineOffsets.bottom}%`}
              stopColor={LOGO_COLOR}
              stopOpacity={LOGO_STOP_OPACITY}
            />
            <stop offset={`${100}%`} stopColor={LOGO_COLOR} stopOpacity={LOGO_STOP_OPACITY} />
          </linearGradient>
        </defs>
        <path
          className={css(styles.arc, styles.arcInner)}
          ref={r => {
            this.arcInner = r;
          }}
          style={{
            strokeDasharray: arcInnerLength,
            strokeDashoffset: arcInnerLength * (lineOffsets.inner / 100)
          }}
          d="M0,40.1H96.55c10.64.66,16.3,7.3,16.3,16.3h0c0,9-5.22,15.24-16.3,16.3H0"
        />
        <path
          className={css(styles.arc, styles.arcOuter)}
          ref={r => {
            this.arcOuter = r;
          }}
          style={{
            strokeDasharray: arcOuterLength,
            strokeDashoffset: arcOuterLength * (lineOffsets.outer / 100)
          }}
          d="M0,7.45H97c38.17,1.17,49,21.94,49,49h0c0,27.06-12.17,46.5-49,49H0"
        />
        <path
          d="M0,145.56H72.7c-.3-5-.5-10-.7-14.9H0Z"
          className={css(styles.line, styles.lineTop)}
        />
        <path
          d="M0,178.15H74.9c-.4-5-.7-9.9-1.1-14.9H0Z"
          className={css(styles.line, styles.lineBottom)}
        />
      </svg>
    );
  }
}

export default LogoAnimated;
