import React from 'react';
import Img from 'gatsby-image';
import Video from '@shared/Video/Video';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import { css } from '@utils';
import styles from './Vision.module.scss';

const MOBILE_BREAKPOINT = 768;

class Vision extends React.PureComponent {
  constructor() {
    super();
    this.state = { windowWidth: 0 };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { windowWidth } = this.state;
    const { eyebrow, headline, copy, iconBulletPoints, video } = this.props;
    const isMobile = windowWidth <= MOBILE_BREAKPOINT;

    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.headerContent)}>
          <div className={css(styles.headerLeftContent)}>
            <InView className={css(styles.inViewWrapper)} triggerOnce>
              {inView => {
                return (
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <p className={css(styles.eyebrow, inView && styles.inView)}>{eyebrow}</p>
                  </Reveal>
                );
              }}
            </InView>

            {isMobile && (
              <InView triggerOnce>
                {inView => {
                  return (
                    <div className={css(styles.videoWrapper)}>
                      <Video
                        decorations={css(styles.video, inView && styles.active)}
                        ref={r => {
                          this.video = r;
                        }}
                        alt={video.description}
                        src={video.file.url}
                      />
                    </div>
                  );
                }}
              </InView>
            )}

            <InView className={css(styles.inViewWrapper)} triggerOnce>
              {inView => {
                return (
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    delay={isMobile ? 0 : 500}
                    duration={700}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <h2 className={css(styles.headline, inView && styles.inView)}>{[headline]}</h2>
                  </Reveal>
                );
              }}
            </InView>

            <InView className={css(styles.inViewWrapper)} triggerOnce>
              {inView => {
                return (
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    delay={isMobile ? 100 : 600}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <div className={css(styles.copy)}>{copy}</div>
                  </Reveal>
                );
              }}
            </InView>
          </div>
          <div className={css(styles.headerRightContent)}>
            {!isMobile && (
              <div className={css(styles.videoWrapper)}>
                <InView triggerOnce>
                  {inView => {
                    return (
                      <div className={css(styles.videoWrapper)}>
                        <Video
                          decorations={css(styles.video, inView && styles.active)}
                          ref={r => {
                            this.video = r;
                          }}
                          alt={video.description}
                          src={video.file.url}
                        />
                      </div>
                    );
                  }}
                </InView>
              </div>
            )}
          </div>
        </div>
        <div className={css(styles.iconBulletListWrapper)}>
          <ul className={css(styles.iconBulletList)}>
            {iconBulletPoints.map((bulletPoint, index) => {
              return (
                <InView
                  key={`BulletPoint__${index}`}
                  className={css(
                    styles.iconBullet,
                    bulletPoint.placeholder && styles.iconBulletPlaceholder
                  )}
                  triggerOnce
                >
                  {inView => {
                    return (
                      <li>
                        <Reveal
                          animateOpacity
                          canPlay={inView}
                          from="bottom"
                          duration={1275}
                          delay={isMobile ? 100 : index * 100 + 100}
                          ease="cubic-bezier(0,0.4,0.4,1)"
                          offset="45px"
                        >
                          <div className={css(styles.iconBulletImageWrapper)}>
                            <Img
                              className={css(styles.iconBulletImage)}
                              fluid={bulletPoint.iconImage}
                              alt={bulletPoint.iconImageAlt || bulletPoint.title}
                            />
                          </div>
                        </Reveal>

                        <Reveal
                          animateOpacity
                          canPlay={inView}
                          from="bottom"
                          duration={1275}
                          delay={isMobile ? 100 : index * 100 + 300}
                          ease="cubic-bezier(0,0.4,0.4,1)"
                          offset="45px"
                        >
                          <div className={css(styles.iconBulletTitle)}>{bulletPoint.title}</div>
                        </Reveal>

                        <Reveal
                          animateOpacity
                          canPlay={inView}
                          from="bottom"
                          duration={1275}
                          delay={isMobile ? 100 : index * 100 + 600}
                          ease="cubic-bezier(0,0.4,0.4,1)"
                          offset="45px"
                        >
                          <div className={css(styles.iconBulletCopy)}>{bulletPoint.copy}</div>
                        </Reveal>
                      </li>
                    );
                  }}
                </InView>
              );
            })}
          </ul>
        </div>
        <InView triggerOnce>
          {inView => {
            return <div className={css(styles.sectionDivider, inView && styles.inView)} />;
          }}
        </InView>
      </div>
    );
  }
}

export default Vision;
