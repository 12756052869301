import React from 'react';
import ReactDOM from 'react-dom';
import ScrollContext from '@context/ScrollContext';
import { css, q, scrollTo, prefix, constrain } from '@utils';

import styles from './ExploreMore.module.scss';

class ExploreMore extends React.PureComponent {
  constructor() {
    super();
    this.state = { active: false, firstLoad: true, portalContainer: null };
    this.firstLoadTimeoutId = null;
  }

  UNSAFE_componentWillMount() {
    const { removeScrollCallback } = this.context;
    removeScrollCallback(this.onScroll);
  }

  componentDidMount() {
    const { addScrollCallback } = this.context;
    this.setState({ portalContainer: q('#layout') });
    this.firstLoadTimeoutId = setTimeout(() => {
      // used to animate on first load
      this.setState({ active: true });
    }, 3200);

    addScrollCallback(this.onScroll);
  }

  onScroll = ({ scrollTop }) => {
    const { active, firstLoad } = this.state;
    if (scrollTop > 0) {
      if (this.firstLoadTimeoutId) {
        clearTimeout(this.firstLoadTimeoutId);
        this.firstLoadTimeoutId = null;
      }
      if (active || firstLoad) {
        this.setState({ active: false, firstLoad: false });
      }
    } else if (!active || firstLoad) {
      this.setState({ active: true, firstLoad: false });
    }
  };

  onClick = () => {
    const heroEl = q('#hero');
    const headerHeight = 60;
    if (heroEl) {
      const newScrollPosition = heroEl.offsetTop + heroEl.getBoundingClientRect().height;
      // Since our condensed header sits on top of the content, we should subtract its height from the new scroll position
      const scrollAdjustedForHeader = constrain(
        newScrollPosition - headerHeight,
        0,
        newScrollPosition
      );
      scrollTo(scrollAdjustedForHeader, 1200);
    }
  };

  render() {
    const { portalContainer, active, firstLoad } = this.state;
    return (
      <>
        {portalContainer &&
          ReactDOM.createPortal(
            <div
              role="presentation"
              className={css(styles.exploreMoreWrapper, active && styles.active)}
              style={{ [prefix('TransitionDuration')]: firstLoad ? '1500ms' : '300ms' }}
              onClick={this.onClick}
            >
              <div className={css(styles.exploreMoreContent)}>Explore</div>
            </div>,
            portalContainer
          )}
      </>
    );
  }
}

ExploreMore.contextType = ScrollContext;
export default ExploreMore;
