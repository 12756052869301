import React from 'react';
import ReactDOM from 'react-dom';
import { graphql } from 'gatsby';
import Section from '@shared/Section/Section';
import SimplifiedHeader from '@shared/SimplifiedHeader/SimplifiedHeader';
import Hero from '@components/Hero/Hero';
import Meta from '@shared/Meta/Meta.query';
import VideoPlayer from '@components/shared/VideoPlayer/VideoPlayer.query';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import Vision from '@components/sections/Vision/Vision.query';
import Download from '@components/shared/Download/Download';
import SocialsLinks from '@shared/SocialsLinks/SocialsLinks';
import Divider from '@shared/Divider/Divider';
import Footer from '@shared/Footer/Footer';
import QuestionsFeedback from '@shared/QuestionsFeedback/QuestionsFeedback';
import idx from 'idx';
import { q } from '@utils';

class IndexPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: null,
      pageMounted: false
    };

    this.portalContainer = null;
  }

  componentDidMount() {
    this.portalContainer = q('#layout');
    setTimeout(() => {
      this.setState({ pageMounted: true });
    }, 0);
  }

  render() {
    const { data } = this.props;
    const { pageMounted, currentSection } = this.state;
    const meta = data.allContentfulAssociate.edges.map(edge => edge.node.meta)[0];
    const videoPlayers = data.allContentfulAssociate.edges.map(edge => edge.node.videoPlayer)[0];
    const vision = data.allContentfulAssociate.edges.map(edge => edge.node.ourVision)[0];
    const heroTitle = data.allContentfulAssociate.edges.map(edge => edge.node.heroTitle)[0];
    const download = {
      title: data.allContentfulAssociate.edges.map(edge => edge.node.downloadTitle)[0],
      copy: data.allContentfulAssociate.edges.map(edge => edge.node.downloadCopy.downloadCopy)[0],
      sections: [
        {
          title: data.allContentfulAssociate.edges.map(edge => edge.node.downloadSectionTitle)[0],
          links: data.allContentfulAssociate.edges.map(edge => {
            return edge.node.downloadLinks.map(link => {
              return { copy: link.copy, url: idx(link, _ => _.media.file.url || link.url) };
            });
          })[0]
        },
        {
          title: data.allContentfulAssociate.edges.map(edge => edge.node.downloadSectionTitle2)[0],
          links: data.allContentfulAssociate.edges.map(edge => {
            return edge.node.downloadLinks2.map(link => {
              return { copy: link.copy, url: idx(link, _ => _.media.file.url) || link.url };
            });
          })[0]
        }
      ]
    };

    const socialsLinks = {
      title: data.allContentfulAssociate.edges.map(edge => edge.node.socialTitle)[0],
      copy: data.allContentfulAssociate.edges.map(edge => edge.node.socialCopy.socialCopy)[0],
      items: data.allContentfulAssociate.edges.map(edge => edge.node.socialLinks)[0],
      link: data.allContentfulAssociate.edges.map(edge => edge.node.socialCta)[0]
    };

    const questionsAndFeedback = {
      title: data.allContentfulAssociate.edges.map(edge => edge.node.questionsAndFeedbackTitle)[0],
      copy: data.allContentfulAssociate.edges.map(
        edge => edge.node.questionsAndFeedbackCopy.childMarkdownRemark.html
      )[0]
    };

    return (
      <>
        {this.portalContainer &&
          ReactDOM.createPortal(<GradientBackground />, this.portalContainer)}

        <Meta {...meta} />

        <SimplifiedHeader noCloseButton={true} center={true} />

        <Hero
          canPlay={pageMounted}
          heroHeadline={heroTitle}
          noLogoAnim={true}
          noHeader={true}
          currentGradientStep={currentSection}
        />

        <Section>
          {!!videoPlayers &&
            videoPlayers.map((props, id) => (
              <VideoPlayer key={id} reverse={(id + 1) % 2 === 0} {...props} />
            ))}

          <Divider />

          <Vision {...vision} />

          <Download
            title={download.title}
            copy={download.copy}
            sections={
              !!download.sections &&
              download.sections.map(section => {
                return {
                  title: section.title,
                  links: section.links.map(link => ({
                    copy: link.copy,
                    url: link.url,
                    target: link.target
                  }))
                };
              })
            }
          />

          <SocialsLinks
            title={socialsLinks.title}
            copy={socialsLinks.copy}
            items={socialsLinks.items.map(item => ({
              icon: item.copy,
              url: item.url,
              target: item.target
            }))}
            link={socialsLinks.link}
          />

          <QuestionsFeedback {...questionsAndFeedback} />
        </Section>
        <Footer currentGradientStep={currentSection} />
      </>
    );
  }
}

export default IndexPage;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulAssociate {
      edges {
        node {
          heroTitle
          meta {
            ...Meta
          }
          videoPlayer {
            ...VideoPlayer
          }
          ourVision {
            ...Vision
          }
          downloadSectionTitle
          downloadSectionTitle2
          downloadTitle
          downloadCopy {
            downloadCopy
          }
          downloadLinks {
            copy
            media {
              file {
                url
              }
            }
            url
            target
          }
          downloadLinks2 {
            copy
            media {
              file {
                url
              }
            }
            url
            target
          }
          socialTitle
          socialLinks {
            copy
            url
            target
          }
          socialCopy {
            socialCopy
          }
          socialCta {
            url
            copy
            target
            media {
              file {
                url
              }
            }
          }
          questionsAndFeedbackTitle
          questionsAndFeedbackCopy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;